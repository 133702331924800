<template>
  <nav class="md:pl-24 z-10">
    <ul class="flex flex-col md:flex-row justify-between items-center">
      <figure class="w-full md:w-40">
        <a href="/home"
          ><img src="../assets/logo.webp" alt="berliner rolladen markisen "
        /></a>
      </figure>
      <li class="ml-5">
        <a
          href="tel:+49 30 841 12 457"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fas fa-phone-square" style="color: #f27405"></i> 030 841 12
          457</a
        ><br />
        <a
          target="_blank"
          href="https://wa.me/+4917680834261"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fab fa-whatsapp" style="color: #f27405"></i>
          0176/80834261</a
        ><br />
        <a
          href="mailto:office@be-ro-ma.de"
          class="text-gray-600 hover:text-yellow-500 font-bold"
          ><i class="fas fa-envelope" style="color: #f27405"></i>
          office@be-ro-ma.de</a
        ><br />
        <div class="text-gray-600 font-bold">
          <i class="fas fa-map" style="color: #f27405"></i> Burgherrenstr. 11,
          12101 Berlin
        </div>
      </li>
      <li
        class="flex flex-col lg:flex-row items-start md:items-center md:m-10 md:pl-10 text-gray-600"
      >
        <div class="mx-5">
          <h3>
            <a
              class="text-gray-600 cursor-pointer hover:text-yellow-500 transition duration-300"
              v-on:click="showProducts = !showProducts"
            >
              Produkte</a
            >
            <div
              class="absolute border-2 border-black bg-white shadow-lg z-20"
              v-if="showProducts"
            >
              <div class="pl-2 pr-5 pt-1 font-semibold text-gray-900">
                Nach Produkten
              </div>
              <hr />
              <ul
                class="pl-2 py-2 hover:bg-gray-100 cursor-pointer text-gray-900"
                @mouseover="
                  hoverRoll = true;
                  hoverJalo = false;
                  hoverMarkis = false;
                  hoverTd = false;
                "
              >
                <a href="/rollladen">Rollladen</a>
                <div
                  class="ml-40 -mt-7 absolute border-2 border-black bg-white shadow-lg z-24"
                  v-if="hoverRoll"
                  @mouseleave="hoverRoll = false"
                >
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/vorbaurollladen">Vorbaurollladen</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/solarrollladen">Solarrollladen</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/rolltor-rollgitter-garagentor"
                      >Rolltor/Rollgitter...</a
                    >
                  </li>
                  <!--<div class="px-5 py-1 hover:bg-gray-100"><a href="#">Rolladenpanzer</a></div>-->
                  <li class="px-5 py-1 hover:bg-gray-100 text-gray-900">
                    <a href="/rollladen-panoramarollladen">Panoramarollladen</a>
                  </li>
                </div>
              </ul>
              <ul
                class="pl-2 py-2 hover:bg-gray-100 cursor-pointer text-gray-900"
                @mouseover="
                  hoverMarkis = true;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                Markisen
                <div
                  class="ml-40 -mt-7 absolute border-2 border-black bg-white shadow-lg z-24 text-gray-900"
                  v-if="hoverMarkis"
                  @mouseleave="hoverMarkis = false"
                >
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/gelenkmarkise">Gelenkarmmarkise</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/halbkassettenmarkise">Halbkassettenmarkise</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/vollkassettenmarkise">Vollkassettenmarkise</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/wintergartenmarkise">Wintergartenmarkise</a>
                  </li>
                </div>
              </ul>

              <h3
                class="pl-2 pr-5 pt-1 hover:bg-gray-100 text-gray-900"
                @mouseover="
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                <a href="/pergolamarkise">Pergolamarkise</a>
              </h3>
              <h3
                class="pl-2 py-2 hover:bg-gray-100 cursor-pointer text-gray-900"
                @mouseover="
                  hoverTd = true;
                  hoverJalo = false;
                  hoverMarkis = false;
                  hoverRoll = false;
                "
              >
                Terassenüberdachung
                <ul
                  class="ml-40 -mt-7 absolute border-2 border-black bg-white shadow-lg z-24"
                  v-if="hoverTd"
                  @mouseleave="hoverTd = false"
                >
                  <li
                    class="px-5 py-1 hover:bg-gray-100 font-semibold text-gray-900 cursor-pointer"
                  >
                    Terrassendach
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/terrassendach-leiner-area-dach">Leiner</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/terrassendach-heroal">Heroal</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/terrassendach-lewens">Lewens</a>
                  </li>
                  <hr />
                </ul>
              </h3>
              <h3
                class="pl-2 pr-5 pt-1 hover:bg-gray-100 text-gray-900"
                @mouseover="
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                <a href="/lamellendach">Lamellendach</a>
              </h3>
              <h3
                class="pl-2 py-2 hover:bg-gray-100 cursor-pointer text-gray-900"
                @mouseover="
                  hoverJalo = true;
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverTd = false;
                "
              >
                Jalousien
                <ul
                  class="ml-40 -mt-7 absolute border-2 border-black bg-white shadow-lg z-24"
                  v-if="hoverJalo"
                  @mouseleave="hoverJalo = false"
                >
                  <li
                    class="px-5 py-1 hover:bg-gray-100 font-semibold text-gray-900 cursor-pointer"
                  >
                    Innenjalousien
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-innenjalousien">Innenjalousie</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-vertikaljalousien">Vertikaljalousie</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-plissee">Plisee</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-rollo">Rollo</a>
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-doppelrollo">Doppelrollo</a>
                  </li>
                  <hr />
                  <li
                    class="px-5 py-1 hover:bg-gray-100 font-semibold text-gray-900 cursor-pointer"
                  >
                    Außenjalousien
                  </li>
                  <li class="px-5 py-1 hover:bg-gray-100">
                    <a href="/jalousien-aussenraffstores">Außenraffstores</a>
                  </li>
                </ul>
              </h3>
              <h3
                class="pl-2 py-2 hover:bg-gray-100 text-gray-900"
                @mouseover="
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                <a href="/zipscreen">Zipscreenanlage</a>
              </h3>
              <!--<div class="pl-2 pr-5 pt-1 hover:bg-gray-100"><a href="#">Verdunklungsanlagen</a></div>-->
              <h3
                class="pl-2 pr-5 pt-1 hover:bg-gray-100 text-gray-900"
                @mouseover="
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                <a href="/tueren&fenster">Türen&Fenster</a>
              </h3>
              <h3
                class="pl-2 pr-5 pt-1 hover:bg-gray-100 text-gray-900"
                @mouseover="
                  hoverMarkis = false;
                  hoverRoll = false;
                  hoverJalo = false;
                  hoverTd = false;
                "
              >
                <a href="/einbruchschutz">Einbruchsschutz</a>
              </h3>
            </div>
          </h3>
        </div>
        <ul class="mx-5">
          <li
            class="text-gray-600 cursor-pointer hover:text-yellow-500 transition duration-300"
          >
            <a v-on:click="showService = !showService">Dienstleistungen</a>
          </li>
          <div
            class="absolute border-2 border-black bg-white shadow-lg z-20 w-48"
            v-if="showService"
          >
            <li class="pl-2 pr-5 pt-1 font-semibold text-gray-900">
              <a href="/reparatur">Reparatur-Service</a>
            </li>
            <hr />
            <li class="pl-2 pr-5 pt-1 font-semibold text-gray-900">
              <a href="/montage">Montage</a>
            </li>
            <!--<div class="pl-2 pr-5 pt-1 font-semibold text-gray-900"><a href="/products">Umrüstung auf Smarthomesteuerung</a></div>
                    <hr>
                    <div class="pl-2 pr-5 pt-1 hover:bg-gray-100"><a href="#">Rolladenumrüstung auf E-Antrieb</a></div>
                    <div class="pl-2 pr-5 pt-1 hover:bg-gray-100"><a href="#">Jalousienumrüstung auf E-Antrieb</a></div>
                    <div class="pl-2 pr-5 pt-1 hover:bg-gray-100"><a href="#">Markisenumrüstung auf E-Antrieb</a></div>
                    <div class="pl-2 pr-5 pt-1 hover:bg-gray-100"><a href="#">Umrüstung auf Solarantrieb</a></div>-->
            <hr />
            <li class="pl-2 pr-5 pt-1 font-semibold text-gray-900">
              <a href="/notdienst">24/7 Notdienst</a>
            </li>
            <hr />
            <li class="pl-2 pr-5 pt-1 font-semibold text-gray-900">
              <a href="/beratung">Beratung</a>
            </li>
          </div>
        </ul>
        <h3
          class="mx-5 hover:text-yellow-500 transition duration-700 text-gray-600"
        >
          <a href="/markisenplaner">Markisenplaner</a>
        </h3>
        <h3
          class="mx-5 hover:text-yellow-500 transition duration-700 text-gray-600"
        >
          <a href="/projekte">Projekte</a>
        </h3>
        <h3
          class="mx-5 hover:text-yellow-500 transition duration-300 text-gray-600"
        >
          <a href="/ueber-uns">Über Uns</a>
        </h3>
        <h3
          class="mx-5 hover:text-yellow-500 transition duration-700 text-gray-600"
        >
          <a href="/kontakt">Kontakt</a>
        </h3>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  data: () => {
    return {
      showService: false,
      showProducts: false,
      hoverRoll: false,
      hoverMarkis: false,
      hoverTd: false,
      hoverJalo: false,
    };
  },
};
</script>
